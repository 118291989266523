import { PROFILE_FETCH, SET_RICHMENU } from "./types"
import axios from "axios"
import queryString from 'query-string'
import { createBrowserHistory } from 'history';
import { liffGetProfile } from '../services/promotion/auth.service';
import { commonConstant } from '../common';
import { fetchLBCAuthToken } from "./tokenActions";
import { updateCustomerInfo } from "./customerActions";

export const history = createBrowserHistory();

const liff = window.liff
const params = queryString.parse(window.location.search);

function isEmpty(obj) {
    return Object.keys(obj).length === 0;
}

function login(redirectUri) {
    //    Sentry.captureMessage('Login '+ redirectUri);
    const linksParam = {
        response_type: 'code',
        client_id: process.env.REACT_APP_CLIENT_ID,
        redirect_uri: redirectUri ? (!redirectUri.includes(process.env.REACT_APP_REDIRECT_URL) ? process.env.REACT_APP_REDIRECT_URL + '/' + redirectUri : redirectUri) : process.env.REACT_APP_REDIRECT_URL,
        state: `${Date.now()}`,
        scope: 'profile openid',
        bot_prompt: "normal",
        prompt: "consent"
    };

    const urlLine = `https://access.line.me/oauth2/v2.1/authorize?${queryString.stringify(
        linksParam,
    )}`;

    window.location.href = urlLine;
}

export const setDynamicRichMenu = async (dispatch, { registerStatus, uid, type }) => {
    if (registerStatus === 'complete') {
        await axios.post(`${process.env.REACT_APP_API_URL}/utils/changeRichMenu/${uid}/${type}/toLayer1`).then(res => {
            dispatch({
                type: SET_RICHMENU,
                payload: res.data
            })
        })
    }
}

export const actionInit = async (redirectUri, dispatch) => {
    try {
      if (!liff.isLoggedIn()) {
        if (redirectUri) {
          if (!redirectUri.includes(process.env.REACT_APP_REDIRECT_URL)) {
            liff.login({ redirectUri: process.env.REACT_APP_REDIRECT_URL + '/' + redirectUri });
          } else {
            liff.login({ redirectUri: redirectUri });
          }
        } else {
          liff.login();
        }
      }
  
      let profile = await liffGetProfile();
      profile.token = await liff.getAccessToken();
  
      const res = await axios.get("https://api.line.me/friendship/v1/status", {
        headers: {
          'Authorization': `Bearer ${profile.token}`
        },
        timeout: process.env.NETWORK_TIMEOUT, 
      });
  
      if (res.data.friendFlag === false) {
        if (sessionStorage.getItem("customer") === null && isEmpty(params)) {
          login(redirectUri);
        }
      } else {
        if (profile) {
          const authToken = await dispatch(fetchLBCAuthToken());
          profile.token = authToken;
          dispatch({
            type: PROFILE_FETCH,
            payload: profile
          });
        }
      }
    } catch (error) {
      if (error.code === 'ECONNABORTED') {
        alert('Request timed out');
      }
      throw error;
    }
  };
  

export const profileFetch = (code) => {
    const redirectUri = window.location.href;
    // Sentry.captureMessage("Profile Fetch : " +  redirectUri);

    return dispatch => {
        // Sentry.captureMessage("Liff init success");
        liff
            .init({
                liffId: process.env.REACT_APP_LIFF_CLIENT_ID
            })
            .then(() => {
                // Sentry.captureMessage("Liff init success");
                actionInit(redirectUri, dispatch);

            }).catch(async (error) => {
                // Sentry.captureMessage("Liff init error");
                if (sessionStorage.getItem("customer") === null && isEmpty(params)) {
                    // Sentry.captureMessage("Empty customer");
                    login(redirectUri)

                } else {
                    if (!redirectUri) {
                        history.push(commonConstant.pathHome);
                        // Sentry.captureMessage("Goto Home : " + commonConstant.pathHome);
                    }

                    try {
                        const profile = await liffGetProfile();
                        const authToken = await dispatch(fetchLBCAuthToken());
                        axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`
                        dispatch({
                            type: PROFILE_FETCH,
                            payload: profile
                        })
                        if (profile?.customerId) {
                          dispatch(updateCustomerInfo())
                        }
                   } catch(error) {
                    throw error;
                  }
                }

            }
            )
    }
}